import React, { useState } from "react"
import Layout from "../components/layout";
import Nav from "../components/Nav"
import PageHeader from "../components/PageHeader"
import Games from "../components/Games"
import Footer from "../components/Footer"
import Search from "../components/Search"
import { graphql } from "gatsby"
export const query = graphql`
    query($limit:Int!, $skip:Int!){
        allStrapiGames(limit: $limit, skip: $skip, sort: {fields: updated_at, order: DESC}) {
            nodes {
                id
                uuid
                slug
                name
                image {
                    sharp: childImageSharp {
                        fluid( maxWidth: 770 ) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                editions {
                    id
                }
                helps {
                    id
                }
                modules {
                    id
                }
                charactersheets {
                    id
                }
            }
            totalCount
            pageInfo {
                currentPage
                itemCount
                pageCount
                perPage
            }
        }
        
    }
`
const GamesPage = ({data}) => {

  const [search, setSearch] = useState("");


  return (
    <Layout
        title="Rolteca | Juegos - Modulos - Ayudas - Fichas de PJ"
        description="Los recursos que necesitas para dirigir tus partidas en un solo lugar">
      <Nav />
      <PageHeader title="Juegos" />
      <Search
        search={search}
        setSearch={setSearch}
        enableSubmit={true}/>
      <Games
        gamesInfo={data}/>
      <Footer />
    </Layout>
  );
};

export default GamesPage;
