import React from "react";
import { Link } from "gatsby";
import course1 from "../assets/images/course-1-1.jpg";
import Img from "gatsby-image"

const Games = ({gamesInfo}) => {
  console.log(gamesInfo);
  const pageInfo = gamesInfo.allStrapiGames.pageInfo;
  const items = [];
  const basicPath = "/juegos";
  if(pageInfo.currentPage === 1){
    items.push( <Link key={basicPath} activeClassName="active" to={basicPath}>
      1
    </Link>)
    items.push( <Link key={basicPath + "-" + "1/"} activeClassName="active" to={basicPath + "-" + "2/" }>
      2
    </Link>)
    items.push( <Link key={basicPath + "-" + "2/"} activeClassName="active" to={basicPath + "-" + "3/"}>
      3
    </Link>)
  }else if(pageInfo.currentPage === pageInfo.pageCount){
    items.push( <Link key={basicPath + "-" + pageInfo.currentPage - "2/" } activeClassName="active" to={basicPath + "-" + (pageInfo.currentPage - 2)+"/"}>
      {pageInfo.currentPage - 2}
    </Link>)
    items.push( <Link key={basicPath + "-" + pageInfo.currentPage -"1/" } activeClassName="active" to={basicPath + "-" + (pageInfo.currentPage - 1)+"/"}>
      {pageInfo.currentPage - 1  }
    </Link>)
    items.push( <Link key={basicPath + "-" + pageInfo.currentPage+"/"} activeClassName="active" to={basicPath + "-" + pageInfo.currentPage+"/"}>
      {pageInfo.currentPage}
    </Link>)
  }else{
    if(pageInfo.currentPage === 2) {
      items.push(<Link key={basicPath} activeClassName="active"
                       to={basicPath}>
        {pageInfo.currentPage - 1}
      </Link>)
    }else{
      items.push(<Link key={basicPath + "-" + pageInfo.currentPage - "1/"} activeClassName="active"
                       to={basicPath + "-" + (pageInfo.currentPage - 1)+"/"}>
        {pageInfo.currentPage - 1}
      </Link>)
    }
    items.push( <Link key={basicPath + "-" + pageInfo.currentPage+"/"} activeClassName="active" to={basicPath + "-" + pageInfo.currentPage+"/"}>
      {pageInfo.currentPage}
    </Link>)
    items.push( <Link key={basicPath + "-" + pageInfo.currentPage +"1/"} activeClassName="active" to={basicPath + "-" + (pageInfo.currentPage + 1)+"/"}>
      {pageInfo.currentPage + 1 }
    </Link>)
  }

  return (
    <section className="course-one course-page">
      <div className="container">
        <div className="row">

          {gamesInfo.allStrapiGames.nodes.map(game=>(
            <div key={game.id} className="col-lg-4">
              <div className="course-one__single">
                <div className="course-one__image">
                  {game.image ?
                    <Img fluid={{ ...game.image.sharp.fluid, aspectRatio: 4 / 5 }} alt={game.name} />
                    :
                    (<img src={"https://rolteca.com/covers/" + game.uuid +"..jpg"}  alt={game.name}/>)
                  }

                </div>
                <div className="course-one__content">
                  <h2 className="course-one__title">
                    <Link to={"/juegos/" + game.slug}>{game.name}</Link>
                  </h2>
                  <div className="course-one__meta">
                    <a href="/course-details">
                      <i className="far fa-folder-open"></i> {game.editions.length} Ediciones
                    </a>
                  </div>
                  <div className="course-one__meta">
                    <a href="/course-details">
                      <i className="far fa-folder-open"></i> {game.helps.length} Ayudas
                    </a>
                  </div>
                  <div className="course-one__meta">
                    <a href="/course-details">
                      <i className="far fa-folder-open"></i> {game.modules.length} Modulos
                    </a>
                  </div>
                  <div className="course-one__meta">
                    <a href="/course-details">
                      <i className="far fa-folder-open"></i> {game.charactersheets.length} Fichas de personaje
                    </a>
                  </div>
                  <Link to={"/juegos/" + game.slug} className="course-one__link">
                    Ver
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="post-pagination">
          <Link to={basicPath}>
            <i className="fa fa-angle-double-left"></i>
          </Link>
          {items}
          {
            pageInfo.pageCount ===1 ? (
                <Link to={basicPath}>
                  <i className="fa fa-angle-double-right"></i>
                </Link>
              ):
              <Link to={basicPath + "-" + pageInfo.pageCount}>
                <i className="fa fa-angle-double-right"></i>
              </Link>
          }
        </div>
      </div>
    </section>
  );
};

export default Games;
