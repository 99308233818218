import React, { useState } from "react"

function Search({search, setSearch, enableSubmit}) {

  const [localSearch, setLocalSearch] = useState(search);
  const onSubmit = e =>{
    if(!enableSubmit)
      e.preventDefault();
    setSearch(localSearch)
  }
  const handleChange = e =>{
    setLocalSearch(e.target.value);
   if(e.target.value.length === 0){
     setSearch("");
   }else if(e.target.value.length > 3) {
     setSearch(e.target.value);
   }
  }
  return (
    <div className="row align-items-center mt-4">
      <div className="col-lg-6 offset-lg-3">
        <div className="sidebar__single sidebar__search">
          <form onSubmit={onSubmit} action="/busqueda" className="sidebar__search-form">
            <input
              onChange={(e) => {handleChange(e)}}
              type="text"
              name="search"
              placeholder="Buscar..."
              value={localSearch}
            />
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Search
